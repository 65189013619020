import React from 'react'

// import {
//     FaFacebook,
//     FaGithub,
//     FaInstagram,
//     FaTwitter,
//     FaTwitch,
// } from 'react-icons/fa'

const Footer = () => {
  return (
    <div className='w-full  bg-slate-900 text-gray-300 py-y px-2 flex center'>
        <div className='max-w-[1240px] mx-auto grid grid-cols-2 md:grid-cols-3 border-b-2 border-gray-600 py-8'>
            <div>
                <h6 className='font-bold uppercase pt-2'></h6>
                <ul>
                    <li className='py-1'></li>
                    <li className='py-1'></li>
                    <li className='py-1'></li>
                    <li className='py-1'></li>
                    <li className='py-1'></li>
                </ul>
            </div>
            <div>
                <h6 className='font-bold uppercase pt-2'></h6>
                <ul>
                    <li className='py-1'></li>
                    <li className='py-1'></li>
                    <li className='py-1'></li>
                    <li className='py-1'></li>
                </ul>
            </div>
            <div>
                <h6 className='font-bold uppercase pt-2'></h6>
                <ul>
                    <li className='py-1'></li>
                    <li className='py-1'></li>
                    <li className='py-1'></li>
                    <li className='py-1'></li>
                    <li className='py-1'></li>
                </ul>
            </div>
        </div>

        {/* <div className='flex flex-col max-w-[1240px] px-2 py-4 mx-auto justify-between sm:flex-row text-center text-gray-500'>
        <p className='py-4'>2022 Workflow, LLC. All rights reserved</p>
        <div className='flex justify-between sm:w-[300px] pt-4 text-2xl'>
            <FaFacebook />
            <FaInstagram />
            <FaTwitter />
            <FaTwitch />
            <FaGithub />
        </div>
        </div> */}
    </div>
  )
}

export default Footer