import React, { useRef, useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './Carousel.css';
import { EffectCoverflow, Pagination, Navigation } from 'swiper';

import slide_image_1 from '../assets/12ft_by_12ft_stone_patio.png';
import slide_image_2 from '../assets/grayscale.png';
import slide_image_3 from '../assets/hero_pic.png';
import slide_image_4 from '../assets/pergola.png';
import slide_image_5 from '../assets/PAG1.jpg';
import slide_image_6 from '../assets/PAG2.jpg';
import slide_image_7 from '../assets/PAG3.jpg';
import slide_image_8 from '../assets/PAG4.jpg';
import slide_image_9 from '../assets/PAG5.jpg';
import slide_image_10 from '../assets/PAG6.jpg';
import slide_image_11 from '../assets/PAG7.jpg';
import slide_image_12 from '../assets/PAG8.jpg';
import slide_image_13 from '../assets/PAG9.jpg';
import slide_image_14 from '../assets/PAG10.jpg';
import slide_image_15 from '../assets/PAG11.jpg';

function Carousel2() {
  const slideImages = [
    slide_image_1,
    slide_image_2,
    slide_image_3,
    slide_image_4,
    slide_image_5,
    slide_image_6,
    slide_image_7,
    slide_image_8,
    slide_image_9,
    slide_image_10,
    slide_image_11,
    slide_image_12,
    slide_image_13,
    slide_image_14,
    slide_image_15,
  ];

  const swiperRef = useRef(null);
  const sliderContainerRef = useRef(null);
  const [showController, setShowController] = useState(true);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5, // Adjust threshold as needed
    };

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        setShowController(entry.isIntersecting);
      });
    };

    const observer = new IntersectionObserver(handleIntersection, options);
    const currentContainerRef = sliderContainerRef.current; // Store in a variable

    if (currentContainerRef) {
      observer.observe(currentContainerRef);
    }

    return () => {
      if (currentContainerRef) {
        observer.unobserve(currentContainerRef);
      }
    };
  }, []); // Removed sliderContainerRef from the dependencies array

  return (
    <div
      name='portfolio'
      className="container"
      style={{ display: 'flex', justifyContent: 'center' }}
      ref={sliderContainerRef}
    >
      <div style={{ width: '80%' }}>
        <h1 className="heading">Let Us Transform Your Outdoor Space Today!</h1>
        <Swiper
          ref={swiperRef}
          effect={'coverflow'}
          grabCursor={true}
          centeredSlides={true}
          loop={true}
          slidesPerView={'auto'}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 2.5,
          }}
          pagination={{ el: '.swiper-pagination', clickable: true }}
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
            clickable: true,
          }}
          modules={[EffectCoverflow, Pagination, Navigation]}
          className="swiper_container"
        >
          {slideImages.map((image, index) => (
            <SwiperSlide key={index}>
              <img src={image} alt={`slide_image_${index + 1}`} />
            </SwiperSlide>
          ))}
        </Swiper>

        {showController && (
          <div className="slider-controler">
            <div className="swiper-button-prev slider-arrow">
              <ion-icon name="arrow-back-outline"></ion-icon>
            </div>
            <div className="swiper-button-next slider-arrow">
              <ion-icon name="arrow-forward-outline"></ion-icon>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Carousel2;
