// ContactModal.js

import React, { useState } from 'react';
import emailjs from '@emailjs/browser'

const ContactModal = ({ isOpen, onClose }) => {

    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [desiredProject, setDesiredProject] = useState('');
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      // Use individual state setters
      switch (name) {
        case 'name':
          setName(value);
          break;
        case 'phoneNumber':
          setPhoneNumber(value);
          break;
        case 'emailAddress':
          setEmailAddress(value);
          break;
        case 'desiredProject':
          setDesiredProject(value);
          break;
        default:
          break;
      }
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
  
      const templateParams = {
        from_name: name,
        to_name: 'PAGLandscaping',
        phone_number: phoneNumber,
        email_address: emailAddress,
        desired_project: desiredProject,
      };
  
      emailjs
        .send('service_xir29lu', 'template_3my5587', templateParams, 'W8BF_8PZGf4aW1cCW')
        .then((result) => {
          console.log(result.text);
          // Reset form fields after submission
          setName('');
          setPhoneNumber('');
          setEmailAddress('');
          setDesiredProject('');
          onClose();
        })
        .catch((error) => {
          console.log(error.text);
        });
    };
    

  return (
    <div className={`modal ${isOpen ? 'flex' : 'hidden'} items-center justify-center fixed inset-0 bg-black bg-opacity-50`}>
      <div className="modal-container bg-white w-96 mx-auto mt-10 p-4 rounded-md">
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                        Name
                    </label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={name}
                        onChange={handleChange}
                        className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                        required
                    />
                </div>

                <div className="mb-4">
                    <label htmlFor="emailAddress" className="block text-sm font-medium text-gray-700">
                        Email Address
                    </label>
                    <input
                        type="email"
                        id="emailAddress"
                        name="emailAddress"
                        value={emailAddress}
                        onChange={handleChange}
                        className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                        required
                    />
                </div>

                <div className="mb-4">
                    <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700">
                        Phone Number
                    </label>
                    <input
                        type="tel"
                        id="phoneNumber"
                        name="phoneNumber"
                        value={phoneNumber}
                        onChange={handleChange}
                        className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                        required
                    />
                </div>

                <div className="mb-4">
                    <label htmlFor="desiredProject" className="block text-sm font-medium text-gray-700">
                        Desired Project
                    </label>
                    <textarea
                        id="desiredProject"
                        name="desiredProject"
                        value={desiredProject}
                        onChange={handleChange}
                        className="mt-1 p-2 border border-gray-300 rounded-md w-full h-24"
                        required
                    />
                </div>
                <div className="flex flex-col items-stretch">
                    <button
                    type="submit"
                    className="bg-orange-500 text-white py-2 px-4 rounded-md mb-2 hover:bg-orange-600"
                    >
                    Submit
                    </button>
                    <button
                    className="bg-gray-300 text-gray-700 py-2 px-4 rounded-md hover:bg-gray-400"
                    onClick={onClose}
                    >
                    Close
                    </button>
                </div>
            </form>
      </div>
    </div>
  );
};

export default ContactModal;
