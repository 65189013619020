import React, { useState } from 'react';
import ContactModal from './ContactModal';
import bgImg from '../assets/hero_pic.png';

const Hero = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  return (
    <div name='home' className='w-full min-h-screen bg-zinc-200 flex flex-col justify-between pt-16'>
      <div className='grid md:grid-cols-2 max-w-[1240px] m-auto'>
        <div className='flex flex-col justify-center md:items-start w-full px-2 py-8' style={{ zIndex: 2 }}>
          {/* Add a higher z-index to ensure the modal is on top */}
          <h1 className='py-3 text-5xl md:text-7xl font-bold'>Transform Your Outdoor Living Space</h1>
          <button className='bg-orange-500 hover:bg-orange-600 border-hidden py-3 px-6 sm:w-[60%] my-4 hover:border-orange-600 hover:text-white transition-all duration-300' onClick={handleModalOpen}>
            Get Started
          </button>
          <ContactModal isOpen={isModalOpen} onClose={handleModalClose} />
        </div>
        <div className='relative overflow-hidden rounded-6xl px-6' style={{ zIndex: 1 }}>
          {/* Add a lower z-index to ensure the image is behind the modal */}
          <img className='w-full rounded-xl' src={bgImg} alt="/" />
        </div>
      </div>
    </div>
  );
};

export default Hero;
