import React from 'react'

const About = () => {
  return (
    <div name='services' className='w-full my-32 flex justify-center'>
        <div className='max-w-[1240px] mx-20'>
            <div className='text-center w-70 mx-auto'>
                <h2 className='text-5xl font-bold'>Crafting Timeless Beauty in Your Backyard: Elevate Your Outdoor Experience </h2>
                <p className='text-3xl py-6 text-gray-500'>Welcome to our landscaping company, where we specialize in creating beautiful stone work and patio designs that will enhance the beauty of your outdoor space. Whether you're looking to add a new patio, walkway, or retaining wall, our team of experts will bring your vision to life.</p>
            </div>

            <div className='grid md:grid-cols-3 gap-1 px-2 text-center'>
                <div className='border py-8 rounded-xl shadow-xl' >
                    <p className='text-3xl font-bold text-orange-500 mx-3'>Step-by-step Breakdown of the Project's Execution</p>
                    <p className='text-gray-400 mt-2 mx-5'>Our project execution involves careful design and material selection, leading to stunning final touches.</p>
                </div>
                <div className='border py-8 rounded-xl shadow-xl' >
                    <p className='text-3xl font-bold text-orange-500 mx-3'>Designing Your Dream Patio</p>
                    <p className='text-gray-400 mt-2 mx-5'>Our expert team will work closely with you to bring your patio vision to life.</p>
                </div>
                <div className='border py-8 rounded-xl shadow-xl' >
                    <p className='text-3xl font-bold text-orange-500 mx-3'>Selecting High-Quality Materials</p>
                    <p className='text-gray-400 mt-2 mx-5'>We only use the finest materials to ensure durability and aesthetic appeal.</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default About