import React from 'react';

const FaqSection = () => {
  return (
    <div name="faqs" className="container mx-auto px-4 lg:flex justify-ce">
      {/* Left Column (Title and Subtitle) */}
      <div className="lg:w-1/2 pl-32">
        <h2 className="text-3xl font-bold mb-4 flex justify-center">Frequently Asked Questions</h2>
        <p className="text-gray-600">Find answers to common questions about our project process, maintenance, and aftercare services.</p>
      </div>

      {/* Right Column (Questions and Answers) */}
      <div className="lg:w-2/3 mt-8 lg:mt-0">
        <div className="mb-8">
          <h3 className="text-xl font-bold mb-2">How long is the process?</h3>
          <p className="text-gray-600">The duration of the process depends on the complexity of the project. We will provide you with an estimated timeline during the initial consultation.</p>
        </div>
        
        <hr className="my-8 border-t border-gray-300" />

        <div className="mb-8">
          <h3 className="text-xl font-bold mb-2">Do you offer maintenance services?</h3>
          <p className="text-gray-600">Yes, we offer maintenance services to ensure the longevity and beauty of your stone work and patio designs. Contact us for more information.</p>
        </div>
        
        <hr className="my-8 border-t border-gray-300" /><div className="mb-8">
          <h3 className="text-xl font-bold mb-2">How do I get in touch?</h3>
          <p className="text-gray-600">You can reach us by phone or email. Visit our Contact page for our contact details and to submit a message through our online form.</p>
        </div>
        
        <hr className="my-8 border-t border-gray-300" /><div className="mb-8">
          <h3 className="text-xl font-bold mb-2">Can I customize the design?</h3>
          <p className="text-gray-600">Yes, we offer customizable designs to suit your preferences and requirements. Our team will work closely with you to bring your vision to life.</p>
        </div>
        
        <hr className="my-8 border-t border-gray-300" /><div className="mb-8">
          <h3 className="text-xl font-bold mb-2">What aftercare services do you provide?</h3>
          <p className="text-gray-600">We provide aftercare services such as sealing, cleaning, and repairs to ensure the longevity and beauty of your stone work and patio designs.</p>
        </div>
        
        <hr className="my-8 border-t border-gray-300" />

        {/* Add more questions and answers as needed */}

        {/* Breakline */}


        {/* Additional content can go here */}
      </div>
    </div>
  );
};

export default FaqSection;
