import React, {useState} from 'react';
import { Link } from 'react-scroll'
import ContactModal from './ContactModal';



import { MenuIcon, XIcon } from '@heroicons/react/outline';
import logo from '../assets/logo_no_background.png'

const Navbar = () => {
    const [nav, setNav] = useState(false)
    const [isModalOpen, setModalOpen] = useState(false);

    const handleClick = () => setNav(!nav);
    const handleClose = () => setNav(!nav);
    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);
  

  return (
    <div className='w-screen h-[80px] z-10 bg-zinc-200 fixed drop-shadow-lg'>
      <div className='px-2 flex justify-between items-center w-full h-full'>
        <div className='flex items-center'>
          <Link
            to="home"
            smooth={true}
            duration={500}
            className='hover:text-orange-500 hover:cursor-pointer transition-colors duration-300'
          >
            <img src={logo} alt="Logo" className="w-48 h-24" />
          </Link>
          <ul className='hidden md:flex'>
              <li>
              <Link
                  to="services"
                  smooth={true}
                  duration={500}
                  className='hover:text-orange-500 hover:cursor-pointer transition-colors duration-300'
                >
                  Services
                </Link>
              </li>
              <li>
                <Link
                  to="portfolio"
                  smooth={true}
                  duration={500}
                  offset={450}
                  className='hover:text-orange-500 hover:cursor-pointer transition-colors duration-300'
                >
                  Portfolio
                </Link>
              </li>
              <li>
                <Link
                  to="faqs"
                  smooth={true}
                  offset={750}
                  duration={500}
                  className='hover:text-orange-500 hover:cursor-pointer transition-colors duration-300'
                >
                  FAQ's
                </Link>
              </li>
          </ul>
        </div>
        <div className='hidden md:flex pr-4'>
          <button className='bg-orange-500 border-hidden px-8 py-3 hover:bg-orange-600 hover:border-orange-600 hover:text-white transition-all duration-300' onClick={handleModalOpen}>
            Contact Us
          </button>
          <ContactModal isOpen={isModalOpen} onClose={handleModalClose} />
        </div>
        <div className='md:hidden mr-4' onClick={handleClick}>
            {!nav ? <MenuIcon className='w-5' /> : <XIcon className='w-5' />}
        </div>
      </div>
      <ul className={!nav ? 'hidden' : 'absolute bg-zinc-200 w-full px-8'}>
        <li className='border-b-2 border-zinc-300 w-full'><Link onClick={handleClose} to="home" smooth={true} duration={500}>Services</Link></li>
        <li className='border-b-2 border-zinc-300 w-full'><Link onClick={handleClose} to="about" smooth={true} offset={-200} duration={500}>Portfolio</Link></li>
        <li className='border-b-2 border-zinc-300 w-full'><Link onClick={handleClose} to="services" smooth={true} offset={-50} duration={500}>About Us</Link></li>
        <div className='flex flex-col my-4'>
          <button className='bg-orange-500 border-hidden px-8 py-3 hover:bg-orange-600 hover:border-orange-600 hover:text-white transition-all duration-300'>
            Contact Us
          </button>
        </div>
      </ul>
    </div>
  );
};

export default Navbar;
